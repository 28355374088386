import { useCallback } from "react";
import AnimateHeight from "react-animate-height";

import Icon from "@/components/icon/Icon";

import MobileNavItem from "./MobileNavItem";

const MobileNavMenu = ({
  expandedMenuOption,
  menu,
  league,
  name,
  onExpandMenuOption,
  onSelectMenuOption,
}) => {
  // Expand / collapse menu option
  const onExpand = useCallback(() => {
    if (expandedMenuOption === name) {
      onExpandMenuOption(null);
    } else {
      onExpandMenuOption(name);
    }
  }, [expandedMenuOption, name, onExpandMenuOption]);

  const namesMap = {
    "Soccer Odds": "Soccer",
    "Soccer Picks": "Soccer",
    "Soccer News": "Soccer",
  };

  const formattedName = namesMap[name] ?? name;

  // If the event that triggered onBlur is not a link, we can
  // assume that the popover has lost focus and should be closed
  const onMenuBlur = useCallback(
    (event) => {
      const isExpanded = expandedMenuOption === name;
      if (
        isExpanded &&
        (!event.relatedTarget || event.relatedTarget.tagName !== "A")
      ) {
        onExpandMenuOption(null);
      }
    },
    [expandedMenuOption, name, onExpandMenuOption]
  );

  const isExpanded = expandedMenuOption === name;
  const navButtonClassname = isExpanded
    ? "relative w-full flex flex-row py-2 items-center text-white text-left font-semibold whitespace-nowrap tracking-wide leading-[28px] nav-bar-caret nav-bar-caret-open"
    : "relative w-full flex flex-row py-2 items-center text-white text-left font-semibold whitespace-nowrap tracking-wide leading-[28px] nav-bar-caret";

  return (
    <div>
      <button
        aria-label={formattedName}
        onClick={onExpand}
        className={`${navButtonClassname} ${
          league ? "gap-2 font-heading font-normal" : ""
        }`}
      >
        <Icon
          className={`${!league ? "mr-5" : "h-[20px] w-[25px]"} text-white`}
          height={league ? "20px" : "16px"}
          color="white"
          stroke="#061235"
          svgfill="black"
          name={`nav${formattedName}`.replaceAll(" ", "")}
          width={"20px"}
        />
        {formattedName}
      </button>
      <AnimateHeight duration={250} height={isExpanded ? "auto" : 0}>
        <div className="flex flex-col" onBlur={onMenuBlur}>
          {Object.entries(menu || {}).map(([key, item]) => (
            <MobileNavItem
              key={key}
              item={item}
              name={key}
              onSelectedParentMenuOption={onSelectMenuOption}
            />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default MobileNavMenu;
