import Link from "next/link";

import Icon from "@/components/icon/Icon";
import { useMobileMenu } from "@/hooks/useMobileMenu";

import MobileNavMenu from "./MobileNavMenu";

const MobileNavItem = ({ item, name, onSelectedParentMenuOption }) => {
  const nameMap = {
    "English Premier League": "premier",
  };
  const iconName = `${nameMap[name] || name.replaceAll(" ", "").toLowerCase()}Category`;
  const { onExpandMenuOption, expandedMenu } = useMobileMenu();
  return (
    <>
      {typeof item === "object" ? (
        <div className="ml-10">
          <MobileNavMenu
            expandedMenuOption={expandedMenu}
            menu={item.menuOptions}
            league={item?.league}
            name={name}
            onExpandMenuOption={onExpandMenuOption}
            onSelectMenuOption={onSelectedParentMenuOption}
          />
        </div>
      ) : (
        <Link
          key={name}
          aria-label={name}
          className="flex gap-2 py-1.5 pl-10 font-heading text-white hover:bg-white hover:text-dark focus:bg-white focus:text-dark"
          href={item ?? ""}
          onClick={onSelectedParentMenuOption}
        >
          <Icon
            className="h-[20px] w-[25px]"
            color={"currentColor"}
            height={"20px"}
            name={iconName}
            width={"25px"}
          />
          {name}
        </Link>
      )}
    </>
  );
};

export default MobileNavItem;
