import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";

import Icon from "@/components/icon/Icon";

import DesktopNavMenu from "./DesktopNavMenu";

const DesktopNavItem = ({ setIsHovered, item, name, onClick }) => {
  const router = useRouter();
  const nameMap = {
    "English Premier League": "premier",
  };

  const iconName = `${nameMap[name] || name.toLowerCase()}Category`.replaceAll(
    " ",
    ""
  );

  useEffect(() => {
    if (typeof item !== "object" && item) {
      router.prefetch(item, null, { priority: true });
    }
  }, []);

  return (
    <>
      {typeof item === "object" ? (
        <div onClick={() => setIsHovered(false)}>
          <DesktopNavMenu menu={item} name={name} isChild />
        </div>
      ) : (
        <Link
          key={name}
          aria-label={name}
          className="flex gap-2 whitespace-nowrap px-5 py-2 font-heading font-normal text-nav-blue hover:bg-blue hover:text-white focus:bg-blue focus:text-white"
          href={item}
          onClick={() => setIsHovered(false)}
        >
          <Icon
            color={"currentColor"}
            className="h-[20px] w-[25px]"
            height={"20px"}
            name={iconName}
            width={"25px"}
          />
          {name}
        </Link>
      )}
    </>
  );
};

export default DesktopNavItem;
