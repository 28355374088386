import Footer from "@/components/footer/Footer";
import Nav from "@/components/nav/Nav";
import { bodyFont, headingFont } from "@/utilities/fonts";

const NavFooterLayout = ({ children }) => {
  return (
    <>
      <main className={`${bodyFont.variable} ${headingFont.variable}`}>
        <Nav />
        {children}
        <Footer />
      </main>
    </>
  );
};

export default NavFooterLayout;
